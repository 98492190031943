* {
  font-family: montserrat, serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --fontSizeB: clamp(31px, 6vw, 36px); // font size big
  --fontSizeM: clamp(20px, 5vw, 22px); // font size middle
  --fontSizeN: clamp(16px, 4.4vw, 18px); // font size big normal
  --fontSizeS: clamp(14px, 4vw, 16px); // font size big small

  // colors
  --pinkGradeBtn: radial-gradient(farthest-corner at 100% 0%, #f9158c 0%, #f9158c 32.81%, #810da4 78.65%, #152bab 100%);
  --blueGradeBtn: radial-gradient(farthest-corner at 100% 0%, #00f4ff 0%, #0626d7 100%);
  --pinkGrade: radial-gradient(122.3% 140% at 100% 0%, #f9158c 0%, #f9158c 72.81%, #810da4 98.65%, #152bab 100%);
  --blueGrade: radial-gradient(128.78% 189.5% at 100% 0%, #00f4ff 0%, #2b49ed 100%);
  --primaryPink: #f9158c;
  --secondaryPink: #810da4;
  --primaryBlue: #0b1b4f;
  --secondBlue: #0626d7;
  --secondLightBlue: rgba(0, 244, 255, 0.55);
  --lightNeutralColor: #f1f5f9;
  --neutralColor: #cbd5e1;
  --darkNeutralColor: #475569;
  --lightSuccesColor: #d1fae5;
  --succesColor: #34d399;
  --darkSuccesColor: #059669;
  --lightWarningColor: #fef3c7;
  --warningColor: #fbbf24;
  --darkWarningColor: #d97706;
  --lightErrorColor: #fee2e2;
  --errorColor: #f87171;
  --darkErrorColor: #dc2626;
}

html,
body {
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

a {
  text-decoration: none;
}
