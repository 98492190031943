@font-face {
  font-family: poppins;
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: poppins;
  src: url("./Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("./Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("./Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("./Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("./Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: montserrat;
  src: url("./Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}
