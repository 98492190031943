$desktop-width: 1220px;

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 56px;
  padding: 10px 20px;
  background-color: #0b1b4f;
  z-index: 999;

  @media screen and (min-width: $desktop-width) {
    backdrop-filter: blur(11px);
    height: 85px;
    padding: 40px 50px;
  }
}

.headerContainerHome {
  background-color: #000615;
  backdrop-filter: blur(11px);

  @media screen and (min-width: $desktop-width) {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
    background-color: initial;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  max-width: 1320px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 300;
}

.logoImage {
  width: 102px;
  aspect-ratio: 492 / 149;
  height: auto;

  @media screen and (min-width: $desktop-width) {
    width: 130px;
  }
}

.navlinksContainer {
  text-align: center;
  position: relative;
}

.navLinks {
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin-top: 55px;
  padding-top: 25px;
  padding-bottom: 150px;
  background: #000615;
  animation: appear 0.2s;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;

  @media screen and (min-width: $desktop-width) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: initial;
    width: initial;
    height: initial;
    margin-top: initial;
    padding-top: initial;
    padding-bottom: initial;
    background: initial;
    z-index: initial;
    overflow: initial;
    gap: 45px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 83%;
    max-width: 700px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    background-color: #f9158c;
    pointer-events: none;

    @media screen and (min-width: $desktop-width) {
      display: none;
    }
  }

  li {
    list-style-type: none;
    margin: 18px 0;
    width: 80%;
    max-width: clamp(230px, 70vw, 360px);
    text-align: left;

    @media screen and (min-width: $desktop-width) {
      width: initial;
      max-width: initial;
      text-align: center;
    }
  }
}

.link {
  font-size: 14px;
  color: #ffffff;
  transition: color 100ms;

  &:hover {
    color: #df0b7a;
  }
}

.kubepayLink {
  background: linear-gradient(10deg, #152bab 0%, #f9158c 65%);
  padding: 8px 28px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  margin-left: -12px;

  &:hover {
    background: linear-gradient(10deg, #122696 0%, #d81278 65%);
    color: #ffffff;
  }
}

.navLinksActive {
  display: flex;
}

.navlinksWrapper {
  width: 80%;
  max-width: clamp(230px, 70vw, 360px);

  @media screen and (min-width: $desktop-width) {
    width: initial;
    max-width: initial;
    margin-top: initial;
  }
}

.selectedLink {
  color: #2ff6ff;
}

.moreButtonIcon {
  aspect-ratio: 12 / 6;
  width: 12px;
  height: auto;
  margin-left: 6px;
  margin-bottom: 1px;
  transition: transform 250ms ease;
}

.moreButtonIconActive {
  transform: rotateX(180deg);
}

.menuButton {
  background-color: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }

  img {
    width: 17px;
    height: 17px;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
