$tablet-width: 1050px;
$desktop-width: 1450px;

.pageContainer {
  width: 100%;
  background: #000615;
  overflow: hidden;
  position: relative;
}

.titleWrapper {
  font-family: montserrat, sans-serif;
  margin: 0 auto;
  padding: 80px 20px 0;
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  position: relative;

  @media screen and (min-width: $tablet-width) {
    padding: 170px 20px 0;
    flex-direction: row;
  }
}

.pageTitle {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2;
  max-width: 500px;
  font-family: inherit;
  margin: 0 auto;

  @media screen and (min-width: $tablet-width) {
    margin: initial;
    font-size: 70px;
  }

  span {
    display: block;
    font-weight: 800;
    font-family: inherit;
  }
}

.partnersContainer {
  margin: 110px auto 250px;
  padding: 0 20px;
  max-width: 1750px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.worm1Mobile {
  position: absolute;
  top: -180px;
  left: -145px;
  width: 365px;
  aspect-ratio: 466/610;
  height: auto;
  background-image: url("/assets/images/worm-2-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(13deg) rotateY(180deg);

  @media screen and (min-width: $tablet-width) {
    display: none;
  }
}

.worm2Mobile {
  position: absolute;
  bottom: -630px;
  left: -35px;
  width: 398px;
  height: auto;
  aspect-ratio: 385/610;
  background-image: url("/assets/images/worm-1-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(280deg);

  @media screen and (min-width: $tablet-width) {
    display: none;
  }
}

.worm3Mobile {
  position: absolute;
  bottom: -370px;
  right: -282px;
  width: 398px;
  height: auto;
  aspect-ratio: 385/610;
  background-image: url("/assets/images/worm-1-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(15deg);

  @media screen and (min-width: $tablet-width) {
    display: none;
  }
}

.splashDesktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: block;
    position: absolute;
    background-image: url("/assets/images/splash.svg");
    background-size: cover;
    background-repeat: no-repeat;
    bottom: -500px;
    left: -670px;
    width: 1300px;
    height: auto;
    aspect-ratio: 2104/2005;
  }
}

.wormDesktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: block;
    position: absolute;
    background-image: url("/assets/images/worm-2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    top: -1170px;
    transform: rotate(20deg);
    right: -930px;
    width: 1300px;
    height: auto;
    aspect-ratio: 931/1219;
  }
}
